import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { navigate } from "gatsby"
import { FormControlLabel, FormSubtitle, SectionTitle } from "../Section"
import styled from 'styled-components'
import BreakpointUp from "../Media/BreakpointUp"
import BlackButton from "../Button/BlackButton"
import ContentPopup from '../ContentPopup'
import { Context } from "../context"

const FormWrap = styled.form`
    display: flex;
    flex-wrap: wrap;
    margin:0 -15px;
`;

const FormLeftPanel = styled.div`
    position:relative;
	width:100%;
	padding:0 15px;
	@media(min-width: 992px){
		flex: 0 0 66.666667%;
        max-width: 66.666667%;
	}
`
const FormRightPanel = styled.div`
    position:relative;
	width:100%;
	padding:0 15px;
	@media(min-width: 992px){
		flex: 0 0 33.333333%;
        max-width: 33.333333%;
	}
`

const CardPrimary = styled.div`
    background-color:#0B619B;   
    color:#fff;
    box-shadow: 0 20px 50px rgba(0,0,0,0.3);
    margin-bottom:30px;
`
const CardPrimaryBody = styled.div`
    padding:30px 15px;
    ${BreakpointUp.sm`	
        padding:30px;
    `} 
    ${BreakpointUp.lg`	
        padding:60px 40px;
    `} 
`
const PriceTable = styled.div`
    margin-bottom:40px;
`
const PriceRow = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`
const PriceThead = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`
const PriceTh = styled.div`
    padding:15px 10px;
`
const PriceThLabel = styled.div`
    color: #fff;
    font-weight:700;
    font-size:18px;
    line-height:34px;

`
const PriceCol = styled.div`
    padding:15px 10px;
`
const PriceLabel = styled.div`
    color: #fff;
    min-width: 100px;
    font-weight:500;
`
const ProductName = styled.div`
    color: #fff;
    > strong{
        font-weight:700;
        color: #000000;
    }    

`
const SubTotal = styled.div`

`
const Total = styled.div`
   font-weight:700;
    font-size: 18px;
    line-height: 28px;
    @media (min-width: 768px) {
        font-size: 20px;
        line-height: 30px;
    }
    @media (min-width: 992px) {   
        font-size: 24px;
        line-height: 34px;
    }
`
const PriceAction = styled.button`
    width:100%;
    margin-top: 20px;
    pointer-events: ${props => props.term ? "unset": "none"};
    opacity: ${props => props.term ? "1": "0.5"};

    .btn{
        width: 100%;
    }
`
const PriceDescription = styled.div`
    margin-bottom:30px;
    padding-bottom:20px;
    border-bottom:1px solid rgba(255,255,255,0.5);
    p{
        font-size:14px;
        line-height:24px;
        > strong{
            font-weight:500;
        }
    }
`

const HelpText = styled.div`
    margin-bottom:15px;
    font-size:14px;
    line-height:24px;
    > a{
        color:rgba(255,255,255,0.5);
        &:hover{
            color:#fff;
        }
    }

    button {
        color: #45BAE7;
    }
`

const CheckoutForm = ({ location }) => {
    const [state, setState] = useState({
        first_name: '', 
        last_name: '', 
        company_name: '', 
        street_address: '', 
        city: '', 
        state: '', 
        pincode: '', 
        phone_no: '', 
        email: '',    
        message: '',
        showTerms: false,
        isContentVisible: false,
    });
    const [subTotal, setSubTotal] = useState();
    const [total, setTotal] = useState();
    const [cartData, setCartData] = useContext(Context);

    const handleModalOpen = () => {
        setState({
            ...state,
            isContentVisible: true
        })
        document.querySelector("body").classList.add("modal-open")
    }
    const handleModalClose = () => {
        setState({
            ...state,
            isContentVisible: false
        })
        document.querySelector("body").classList.remove("modal-open")
    }
    const handleChange = (event) => {
        const {id, value} = event.target
        setState({
            ...state,
            [id]: value
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        let orderMessage = "";
        cartData?.map((item, i) =>{
            orderMessage = orderMessage + "- Product Name: " + item.productName +  ", Product URL: " + location.origin + item.url + ", Product SKU: " + item.productSku + ", Product Qty: " + item.count + ", Product Price: $" + item.price * item.count
        })
        const data = {
            source: location.origin,
            page: location.pathname,
            form: "Checkout Form",
            first_name: state.first_name,
            last_name: state.last_name,
            address_line_1: state.street_address,
            city: state.city,
            state: state.state,
            zipcode: state.pincode,
            phone_no: state.phone_no,
            email: state.email,
            comment: "Company Name:" + state.company_name + "Message:" + state.message + "Ordered Notes: "+orderMessage,
            api_token: process.env.GATSBY_FORM_CRM_TOKEN,
        }

        if (typeof window !== `undefined`) {
			document.getElementById("orderBtn").setAttribute("disabled", "");
		}
        axios({
          method: "post",
          url: "https://crm.senseicrm.com/api/create-lead-wordpress",
          data: data,
          headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
        }).then((res) => {
            setState({ source: '', page: '', form: '', first_name: '', last_name: '', company_name: '', street_address: '', city: '', state: '', pincode: '', phone_no: '', email: '',    message: ''});
            navigate("/thank-you-place-order");
          }).catch((error) => {
              alert(error)
          });
          
    }

    useEffect(() => {
        let subTotalPrice = 0
        cartData?.map((cartItem) => {
            subTotalPrice = subTotalPrice + cartItem.price * cartItem.count
        })
        setSubTotal(subTotalPrice);
        setTotal(subTotalPrice);
    }, [cartData])

    return(                     
        <>
            <FormWrap id="checkout" onSubmit={(e) => handleSubmit(e)}>
                <FormLeftPanel>
                    <div className="form-group-bordered">
                        <input type="text" id="first_name" className="form-control" onChange={(e) => handleChange(e)} pattern="[A-Za-z\s]+$" required title=" "/>
                        <FormControlLabel htmlFor="first_name" className={state.first_name!==''?"control-label active":"control-label"}>First Name</FormControlLabel>
                    </div>
                    <div className="form-group-bordered">
                        <input type="text" id="last_name" className="form-control" onChange={(e) => handleChange(e)} pattern="[A-Za-z\s]+$" required />
                        <FormControlLabel htmlFor="last_name" className={state.last_name!==''?"control-label active":"control-label"}>Last Name</FormControlLabel>
                    </div>                
                    
                    <div className="form-group-bordered">
                        <input type="text" id="company_name" className="form-control" onChange={(e) => handleChange(e)} required />
                        <FormControlLabel htmlFor="company_name" className={state.company_name!==''?"control-label active":"control-label"}>
                        Company Name (Optional)</FormControlLabel>
                    </div>
                    
                    <FormSubtitle>COUNTRY / REGION *United States (US)</FormSubtitle>
                   
                    <div className="form-group-bordered">
                        <input type="text" id="street_address" className="form-control" onChange={(e) => handleChange(e)} required />
                        <FormControlLabel htmlFor="street_address" className={state.street_address!==''?"control-label active":"control-label"}>Street Address</FormControlLabel>
                    </div>
                
                    <div className="form-group-bordered">
                        <input type="text" id="city" className="form-control" onChange={(e) => handleChange(e)} required title=" "/>
                        <FormControlLabel className={state.city!==''?"control-label active":"control-label"}>Town / City</FormControlLabel>
                    </div>

                    <div className="form-group-bordered">
                        <select id="state" className="form-control" onChange={(e) => handleChange(e)} aria-label="state">
                        <option value="">Select State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </div>

                    <div className="form-group-bordered">
                        <input type="text" id="pincode" className="form-control" onChange={(e) => handleChange(e)} pattern="[0-9]+$" required title=" "/>
                        <FormControlLabel htmlFor="pincode" className={state.pincode!==''?"control-label active":"control-label"}>Zip Code</FormControlLabel>
                    </div> 

                    <div className="form-group-bordered">
                        <input type="tel" id="phone_no" className="form-control" onChange={(e) => handleChange(e)} pattern="[0-9]{10}" required title=" "/>
                        <FormControlLabel htmlFor="phone_no" className={state.phone_no!==''?"control-label active":"control-label"}>Phone Number</FormControlLabel>
                    </div> 

                    <div className="form-group-bordered">
                        <input type="email" id="email" className="form-control" onChange={(e) => handleChange(e)} required title=" "/>
                        <FormControlLabel htmlFor="email" className={state.email!==''?"control-label active":"control-label"}>Email Address</FormControlLabel>
                    </div>
                    
                    <SectionTitle mb="30px" mt="60px" textAlign="left">Additional Information</SectionTitle>
                    <div className="form-group-bordered">
                        <textarea type="text" id="message" className="form-control" onChange={(e) => handleChange(e)} title=" " />
                        <FormControlLabel className={state.message!==''?"control-label active":"control-label"}>Order Notes (Optional)</FormControlLabel>
                    </div>
                </FormLeftPanel>
                <FormRightPanel>
                    <div className="isSticky">
                        <CardPrimary>
                            <CardPrimaryBody>                      
                                <PriceTable>
                                    <PriceThead>
                                        <PriceTh><PriceThLabel>PRODUCT</PriceThLabel></PriceTh>
                                        <PriceTh><PriceThLabel>SUBTOTAL</PriceThLabel></PriceTh>
                                    </PriceThead>
                                    {cartData?.map((item) => (
                                        <PriceRow>
                                            <PriceCol><ProductName>{item.productName} <strong>X {item.count}</strong></ProductName></PriceCol>
                                            <PriceCol><SubTotal>${(item.price*item.count).toLocaleString("en-US")}</SubTotal></PriceCol>
                                        </PriceRow>
                                    ))}
                                    <PriceRow>
                                        <PriceCol><PriceLabel>SUBTOTAL</PriceLabel></PriceCol>
                                        <PriceCol><SubTotal>${parseFloat(subTotal).toLocaleString("en-US")}</SubTotal></PriceCol>
                                    </PriceRow>
                                    <PriceRow>
                                        <PriceCol><PriceLabel>TOTAL</PriceLabel></PriceCol>
                                        <PriceCol><Total>${parseFloat(total).toLocaleString("en-US")}</Total></PriceCol>
                                    </PriceRow>
                                </PriceTable>
                                <PriceDescription>
                                    <p><strong>Thank you for ordering from Coast to Coast Carports. One of our building specialists will get in touch with you shortly to discuss the order details. Please be assured you will NOT be charged until after our building expert reaches out.</strong></p>
                                </PriceDescription>
                                <HelpText>Your personal data will only be used to process the order. Please read our <button type="button" onClick={handleModalOpen}>Privacy Policy</button> which will guide you on how we collect, store, and use the information you provide online.</HelpText>
                                <PriceAction term={cartData?.length > 0? true: false} type="submit" id="orderBtn"><BlackButton text="Place Order" /></PriceAction>
                            </CardPrimaryBody>
                        </CardPrimary>                
                    </div>
                </FormRightPanel>
                
            </FormWrap>                
            <ContentPopup location={location} isVisible={state.isContentVisible} onClose={handleModalClose} />
        </>
    )
}

export default CheckoutForm